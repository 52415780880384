"use client";
import { ErrorPage } from "@hkexpressairwayslimited/ui";
import { getLangLocale } from "modules/common/common/utils/i18n-utils";
import { useTransContent } from "modules/common/trans-content/transContent";
import { useRouter } from "next/navigation";
import { useEffect } from "react";

export const NotFound = () => {
  const { t, tPlain } = useTransContent();
  const router = useRouter();

  function BackToHomePage() {
    router.replace(`/${getLangLocale()}`);
  }

  useEffect(() => {
    router.prefetch("/");
  }, [router]);

  return (
    <ErrorPage
      title={t("web.page.not.found.title")}
      text={t("web.page.not.found.text")}
      desc={tPlain("web.page.not.found.desc")}
      onCountdownEnd={BackToHomePage}
    />
  );
};
